import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '../services';
import { LocalStorage, Paths } from '../enums';

@Injectable({
  providedIn: 'root',
})
export class ReverseAuthGuard {
  constructor(private localStorageService: LocalStorageService, private router: Router) { }
  canActivate() {
    if (!this.localStorageService.getData(LocalStorage.accessToken)) {
      return true;
    }

    this.router.navigate(["/" + Paths.application + "/" + Paths.dashboard]);
    return false;
  }
}
